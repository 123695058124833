<template>
    <div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="fa fa-connectdevelop icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Referral Users</div>
                </div>
            </div>
        </div>
    </div>
  </div>
    <div class="row">
        <div class="col-12">
            <div class="main-card mb-3">
              <div class="card">
                <div class="card-header text-white bg-danger">
                  List of referral users
                </div>
                <div class="card-body">
            <div class="overflow-auto">
      <b-row>

      <b-col lg="6" class="my-1 mb-2">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="reg_userName">Register User</b-form-checkbox>
            <b-form-checkbox value="ref_userName">Referal User</b-form-checkbox>
            <b-form-checkbox value="ref_user_cat">Category</b-form-checkbox>
            <b-form-checkbox value="register_user_status">Package</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </b-row>
                <b-table striped hover :items="allRefUsers" :per-page="perPage"
                :current-page="currentPage" :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                >
                <!-- :disabled="data.item.register_user_status=='Free' | !data.item.ref_widthdraw" -->
                 
                 <template #cell(referal_id)="data">
                   <b-button :disabled="data.item.ref_widthdraw!='Request Submitted'" variant="outline-info" @click="adminPaid(data.item.referal_id)">
                     <i v-if="data.item.ref_widthdraw=='Request Submitted'">
                       Click to Pay
                     </i>
                     <i v-else> Paid</i>
                   </b-button>
                <!-- <i>{{ data.item.referal_id }}</i> -->
                </template>
                <template #cell(reference_user_id)="data">
                  <b-button variant="outline-success" @click="accountDetail(data.item.reference_user_id)">
                    Account
                  </b-button>
                </template>
                 <template #cell(reg_userName)="data">
                <i>{{ data.item.reg_userName }}</i>
                </template>
                 <template #cell(ref_userName)="data">
                <i>{{ data.item.ref_userName }}</i>
                </template>
                <template #cell(ref_user_cat)="data">
                <i>{{ data.item.ref_user_cat }}</i>
                </template>
                <template #cell(user_type)="data">
                <i style="text-transform:capitalize">{{ data.item.user_type }}</i>
                </template>
                <template  #cell(ref_widthdraw_date)="data">
                <i>{{changeDate(data.item.ref_widthdraw_date, "fullDate")}}</i>
                </template>
                <template #cell(register_user_status)="data">
                <i>{{ data.item.register_user_status }}</i>
                </template>
                <template #cell(ref_widthdraw)="data">
                <i v-if="data.item.ref_widthdraw=='Paid'">Paid</i>
                <i v-if="!data.item.ref_widthdraw=='Request' &  data.item.register_user_status!='Free' ">
                  UnPaid
                </i>
                </template>
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
            </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <b-modal
      id="modalAccountDetailsPresent"
      ref="modalAccountDetailsPresent"
      title="Account Details"
      hide-footer
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Account Name"
          label-for="Account_Name">
         <b-form-input :disabled='"true"' id="Account_Name" v-model="accountDetails.Account_Name"></b-form-input>
        </b-form-group>
      </form>
      <b-form-group
          label="Account Number"
          label-for="Account_Number">
         <b-form-input :disabled='"true"' id="Account_Number" v-model="accountDetails.Account_Number"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Bank Name"
          label-for="Bank_Name">
         <b-form-input :disabled='"true"' id="Bank_Name" v-model="accountDetails.Bank_Name"></b-form-input>
        </b-form-group>
    </b-modal>
</div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
export default {
    data(){
        return{
            allRefUsers:null,
            perPage: 15,
            sortDirection: 'asc',
            currentPage: 1,
            minDate:'',
            maxDate:'',
            totalRows: 1,
            haswithdrawlOption:false,
            accountDetails:{
              user_id:0,
              Account_Name:'',
              Account_Number:'',
              Bank_Name:'',
              ref_widthdraw:'',
              ref_widthdraw_date:'',
              url:'users/updateAccountDetails'
            },
            filter: null,
            filterOn: [],
            fields: [
              {
            key: 'referal_id',
            label: '',
            sortable: false,
          },
          {
            key: 'reference_user_id',
            label: '',
            sortable: false,
          },
              {
            key: 'ref_userName',
            label: 'Referral User',
            sortable: true,
            variant: 'success'
          },
          {
            key: 'ref_user_cat',
            label: 'Referral Category',
            sortable: true,
            variant: 'warning'
          },
          // {
          //   key: 'ref_user_cat_id',
          //   label: 'Referral Category ID',
          //   sortable: true,
          //   variant: 'warning'
          // },
          {
            key: 'reg_userName',
            label: 'Registered User',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'ref_widthdraw_date',
            label: 'Withdraw Date',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'register_user_status',
            label: 'Register User Package',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'ref_widthdraw',
            label: 'Payment Status',
            sortable: false,
            variant: 'success'
          }
        ],
        }
    },
    methods:{
        ...mapActions({
            getData: 'speakmodule/fetchQuestion',
            saveData: 'speakmodule/saveAnswer',
        }),
        ...mapGetters({
            getDataResponse: 'speakmodule/questions',
            dataResponse: 'speakmodule/AnswerResponse',
        }),
        changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("D-MM-YYYY");
          } 
          else if(dateType === "saveDate"){
             return moment(date1).format("YYYY-MM-D 12:00:00");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        fetchReferalRequestedUsers(){
            this.getData('users/getRefall').then(()=>{
                var refUsers=this.getDataResponse()
                if(refUsers.success)
                {
                    this.allRefUsers=refUsers.data
                    console.log(refUsers.data)
                    const anyWithdrawl=this.allRefUsers.filter((item)=>{
                      return item.register_user_status!='Free' & item.ref_widthdraw==''
                    })
                    if(anyWithdrawl!=null)
                    {
                      if(anyWithdrawl.length>0){
                        this.haswithdrawlOption=true
                      }
                    }
                }
            });
        },
       
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      accountDetail(user_id){
          this.getData('users/userAccountDetail='+user_id).then(()=>{
            const accRes=this.getDataResponse()
            if(accRes.success==1)
              {
                this.accountDetails.user_id=user_id
                this.accountDetails.Account_Name=accRes.data.Account_Name
                this.accountDetails.Account_Number=accRes.data.Account_Number
                this.accountDetails.Bank_Name=accRes.data.Bank_Name
                return this.$refs['modalAccountDetailsPresent'].show()

              }
          })
      },
      adminPaid(referralId){
        var selectedReferral=this.allRefUsers.filter((item)=>{
          return item.referal_id==referralId
        })[0]
        ////debugger
        const currentDate=new Date()
        //console.log(selectedReferral)
        
          var data={
            url:'users/updateWithDrawRequestByAdmin',
            ref_widthdraw:'Paid',
            admin_withdraw_request_date: this.changeDate(currentDate,'saveDate'),
            referal_id:referralId
          }
          this.saveData(data).then(()=>{
            const res=this.dataResponse()
              //console.log(res)
              if(res.success==1)
              {
                this.haswithdrawlOption=false
                this.$alert(res.message)
                this.fetchReferalRequestedUsers()
              }
          })
        
      }
    },
    computed: {
      rows() {
        return this.allRefUsers.length
      }
    },
    beforeMount(){
        this.fetchReferalRequestedUsers()
    }
}
</script>
<style>
    
</style>